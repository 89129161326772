import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';
import useUserData from '../services/User_data';
import Header_2 from '../Header_2';
import Footer from '../Home_sections/Footer';
import ActivateMessageComponent from '../Home_sections/Activate_component';
import VerifyToken from '../../Verify_token';
import LoadingSpinner from '../../LoadingSpinner';
import UserProfileForm from './User_profile_form';


function Ppage() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  const prevAccessTokenRef = useRef(false);
  const isTokenValido = useRef(undefined);
  const [cookies] = useCookies(['betaitoken', 'languageai', 'betairefreshtoken']);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async () => {
      await new Promise(resolve => setTimeout(resolve, 50));
      const betairefreshtoken = Cookies.get('betairefreshtoken');
      if (betairefreshtoken) {
        try {
          const isTokenValid = await VerifyToken(betairefreshtoken, Cookies, prevAccessTokenRef);
          isTokenValido.current = isTokenValid;
          setIsLoggedIn(isTokenValido.current);
        } catch (error) {
          console.error("Error verifying token:", error);
          isTokenValido.current = false;
          setIsLoggedIn(isTokenValido.current);
        }
      } else {
        console.error("Missing betairefreshtoken");
        isTokenValido.current = false;
        setIsLoggedIn(isTokenValido.current);
      }
    };

    verifyToken();
  }, [isTokenValido, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn === false) {
      navigate('/');
      window.location.reload();
    }
  }, [isLoggedIn, navigate]);

  const { data: user_data, isLoading: loadingUserData } = useUserData(cookies.betaitoken, isLoggedIn);

  useEffect(() => {
    if (user_data) {
      setIsLoading(false);
    }
  }, [user_data]);

  const language = cookies.languageai || 'en';

  return (
      <div className="App">
        <main>
          {isLoading || loadingUserData ? (
            <div>
              <LoadingSpinner />
            </div>
          ) : isLoggedIn ? (
            <>
              <section>
                <Header_2 user_data={user_data} />
              </section>
              <section className='activate_message'>
                <ActivateMessageComponent user_data={user_data} language={language} />
              </section>
              <section className='profile_section'>
                <UserProfileForm user_data={user_data} token={cookies.betaitoken} language={language} />
              </section>
              <section>
                <Footer language={language} />
              </section>
            </>
          ) : (
            <>
              <p></p>
            </>
          )}
        </main>
      </div>
  );
}

export default Ppage;
