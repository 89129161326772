// usePastData.js
import { useQuery } from 'react-query';
import ApiService from './api_service';

const fetchPastData = async ({ queryKey }) => {
  const [_, token] = queryKey;
  const apiService = new ApiService(token);
  const data = await apiService.fetchPastData();
  return data;
};

const usePastData = (token, isLoggedIn) => {
  return useQuery(['pastData', token], fetchPastData, {
    enabled: !!token && isLoggedIn, // Only fetch data if token is present and user is logged in
  });
};

export default usePastData;
