// usePackResultsData.js
import { useQuery } from 'react-query';
import ApiService from './api_service';

const fetchPackResultsData = async ({ queryKey }) => {
  const [_, { token, isLoggedIn }] = queryKey;
  const apiService = new ApiService(token);
  let data;
  if (isLoggedIn) {
    data = await apiService.fetchPacksResults();
  } else {
    data = await apiService.fetchPacksResultsNoAuth();
  }
  return data;
};

const usePackResultsData = (token, isLoggedIn) => {
  return useQuery(['packResultsData', { token, isLoggedIn }], fetchPackResultsData, {
    enabled: token !== undefined || isLoggedIn !== undefined // Fetch data if token or isLoggedIn state is present
  });
};

export default usePackResultsData;

