import React from 'react';
import './login.css';

const GoogleLoginButton = (props) => {
  const googleClientId = "96331218273-ej3tfvub4eivi6bjvl653pe7cujnf3kk.apps.googleusercontent.com";
  const apiBaseURL = "https://api.aipickz.com";

  const onGoogleLoginSuccess = () => {
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const redirectUri = 'api/v1/auth/login/google/';

    // Scope indicates the info of the user needed from google, I have
    // only asked for the email, profile picture, and full name
    const scope = [
      'https://www.googleapis.com/auth/userinfo.email',
      'https://www.googleapis.com/auth/userinfo.profile'
    ].join(' ');

    const params = {
        response_type: 'code',
        client_id: googleClientId,
        redirect_uri: `${apiBaseURL}/${redirectUri}`,
        prompt: 'select_account', // User will always select an account
        access_type: 'offline', // To get a refresh token
        scope
      };
  
      const urlParams = new URLSearchParams(params).toString();
      window.location = `${googleAuthUrl}?${urlParams}`;
    };
  
    return (
      <button className="btn_primary_login" onClick={onGoogleLoginSuccess} >
         <img src={'https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png'} alt="Google Logo" style={{ width: '50px', height: '100%', marginRight: '8px'}} />
        {props.trad}
      </button>
    );
  };
  
  export default GoogleLoginButton;