import './ActivatePremium.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';;


const translations = {
  en: {
    please: 'Not enough picks?',
    here: 'Upgrade to Premium',
  },
  es: {
    please: '¿No tienes suficientes picks?',
    here: 'Mejorar a Premium',
  },
};

const ActivatePrem = ({ user_data, language }) => {
  const [isClosed, setIsClosed] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => {
    setIsClosed(true);
  };

  const handleButtonClick = () => {
    navigate('/pricing');
  };
  if (user_data && user_data.user && user_data.user.subscription_type === "premium") {
    return null; // If user is free, don't render anything
  }

  const containerClassName = `user-profile-container2 ${isClosed ? 'closed' : ''}`;
  const { please, here } = translations[language] || translations.en;

  return (
    <div className={containerClassName}>
      {!isClosed && (
        <>
          <div className="verification-message2">
            <p>{please}</p>
            <button className="activate-button2" onClick={handleButtonClick}>
              {here}
            </button>
          </div>
          <button className="close-button2" onClick={handleClose}>
            &times;
          </button>
        </>
      )}
    </div>
  );
};

export default ActivatePrem;
