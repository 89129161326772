import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Activate_message.css';

const translations = {
  en: {
    please: 'Please verify your account to manage pick notifications',
    here: 'here',
    
  },
  es: {
    please: 'Por favor verifica tu cuenta para gestionar notificaciones de picks',
    here: 'aquí',
  },
};

const ActivateMessage = ({ user_data, language }) => {
  try{
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = () => {
    setIsClosed(true);
  };

  if (user_data.user.is_verified) {
    return null; // If user is verified, don't render anything
  }

  const containerClassName = `user-profile-container ${isClosed ? 'closed' : ''}`;

   const { please, here} = translations[language] || translations.en;

  return (
    <div className={containerClassName}>
      {!isClosed && (
        <>
          <div className="verification-message">
            <p>
              {please}{' '}
              <Link to="/profile">{here}</Link>
            </p>
          </div>
          <button className="close-button" onClick={handleClose}>
            &times;
          </button>
        </>
      )}
    </div>
  );
} catch (error) {}
};  
export default ActivateMessage;
