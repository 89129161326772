import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './login.css';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import GoogleLoginButton from './GoogleLoginButton';
import onGoogleLoginSuccess from './GoogleLoginButton';
import Header_2 from '../Header_2';
import Header from '../Header';
import VerifyToken from '../../Verify_token';
import Cookies from 'js-cookie';


const translations = {
  en: {
    password_trad: 'Password',
    dont: "Don't have an account?",
    reg: 'Register here',
    or: 'Or',
    incorrect: 'Incorrect email or password.',
    enter_email: 'Enter your email',
    enter_password: 'Enter your password',
    forgot: 'Forgot your password?',
    login_google: 'Login with Google'
    
  },
  es: {
      password_trad: 'Contraseña',
      dont: 'Aún no tienes una cuenta?',
      reg: 'Regístrate aquí',
      or: 'O',
      incorrect: 'Correo o contraseña incorrectos.',
      enter_email: 'Ingresa tu correo',
      enter_password: 'Ingresa tu contraseña',
      forgot: 'Olvidaste tu contraseña?',
      login_google: 'Ingresa con Google'

  },
};

function Login() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const prevAccessTokenRef = useRef(false);
  const isTokenValido = useRef(undefined);

  useEffect(() => {
    const verifyToken = async () => {
      // Add a delay of 1 second
      await new Promise(resolve => setTimeout(resolve, 50));
      
      const betairefreshtoken = Cookies.get('betairefreshtoken');
      if (betairefreshtoken) {
        try {
          // Assuming VerifyToken is an asynchronous function that returns a Promise
          const isTokenValid = await VerifyToken(betairefreshtoken, Cookies, prevAccessTokenRef);
          isTokenValido.current = isTokenValid;
          setIsLoggedIn(isTokenValido.current)
        } catch (error) {
          console.error("Error verifying token:", error);
          isTokenValido.current = false;
          setIsLoggedIn(isTokenValido.current)
        }
      } else {
        console.error("Missing betairefreshtoken");
        console.log(isTokenValido.current);
        isTokenValido.current = false;
        setIsLoggedIn(isTokenValido.current)
    
      }
    };

    verifyToken();
    setIsLoggedIn(isTokenValido.current)
  }, [isTokenValido, isLoggedIn]);


  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
      window.location.reload();
    }
  }, [isLoggedIn, navigate, []]);

  

  const [cookies, setCookie, removeCookie] = useCookies(['betaitoken', 'languageai', 'betairefreshtoken']);

  useEffect(() => {
    if (cookies.betaitoken) {
      navigate('/');
      window.location.reload();
    }
  }, [isLoggedIn, navigate, []]);


  const language = cookies.languageai || 'en';


  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const apiBaseURL = "https://api.aipickz.com";

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loggedIn, setLoggedIn] = useState(false);
  const isLoginButtonDisabled = !email || !password;

  const handleLogin = async (e) => {
    e.preventDefault();

    if (email.toLowerCase().endsWith('@gmail.com')) {
      const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
      const redirectUri = 'api/v1/auth/login/google/';
      const scope = [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile'
      ].join(' ');

      const params = {
        response_type: 'code',
        client_id: googleClientId,
        redirect_uri: `${apiBaseURL}/${redirectUri}`,
        prompt: 'select_account',
        access_type: 'offline',
        scope
      };

      const urlParams = new URLSearchParams(params).toString();
      window.location = `${googleAuthUrl}?${urlParams}`;
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_LOGIN,
        JSON.stringify({
          email,
          password,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      setCookie('betaitoken', response.data.access);
      setCookie('betairefreshtoken', response.data.refresh);
      setIsLoggedIn(true);

      navigate('/login');
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setError('Incorrect email or password.');
      } else if (err.response && err.response.data) {
        setError(err.response.data.message);
      } else {
        setError('An error occurred. Please try again later.');
      }
    }
  };

  const handleRegisterRedirect = () => {
    navigate('/register'); // Replace '/register' with your registration page route
  };

  const handleForgotRedirect = () => {
    navigate('/forgot-password'); // Replace '/register' with your registration page route
  };

  const { password_trad, dont, reg, or, incorrect, enter_email, enter_password, forgot, login_google} = translations[language] || translations.en;

  return (
    <div>
      {isLoggedIn ? <Header_2 /> : <Header />}
      <div className="login-container">
        <form className="login-form" onSubmit={handleLogin} noValidate>
          <h1>Login</h1>

          <div className="form-group">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="text"
              id="email"
              className="form-control"
              placeholder= {enter_email}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="password" className="form-label">
              {password_trad}
            </label>
            <input
              type="password"
              id="password"
              className="form-control"
              placeholder= {enter_password}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          {(error === 'Incorrect email or password.') && (
            <div>
            <p className="error-message" style={{ color: 'red' }}>
              {incorrect}
            </p>
            
            
             <p
             className="register-link"
             onClick={handleForgotRedirect}
             style={{
               cursor: 'pointer',
               color: '#007BFF', // You can change the color to your preference
               fontWeight: 'bold',
               textDecoration: 'underline',
             }}
           >
             {forgot}
           </p>
            </div>
          )}

          <button type="submit" className="btn_primary_login" disabled={isLoginButtonDisabled}>
            Login
          </button>

         

          <p>{or} </p>
          <GoogleLoginButton trad = {login_google} />

          <p>
            {dont}{' '}
            <span
              className="register-link"
              onClick={handleRegisterRedirect}
              style={{
                cursor: 'pointer',
                color: '#007BFF', // You can change the color to your preference
                fontWeight: 'bold',
                textDecoration: 'underline',
              }}
            >
              {reg}
            </span>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Login;
