import React from "react";
import './Box.css';

const Box = (props) => {
  const { title, btnClass, btnTitle, price, feature, headerColor , onButtonClick } = props;
  return (
    <div className="card mb-4 shadow">
      <div className="card-header" style={{ backgroundColor: headerColor }}>
        <h4 className="my-0 font-weight-normal">{title}</h4>
      </div>
      <div className="card-body">
        <h1 className="card-title pricing-card-title">
          ${price} <small className="text-muted"> </small>
        </h1>
        <button type="button" className={"btnpricing"} onClick={onButtonClick}>
          {btnTitle}
        </button>
        <ul className="list-unstyled mt-3 mb-4">
          {feature &&
            feature.map((data, index) => {
              return <li key={index}>{data}</li>;
            })}
        </ul>
      </div>
    </div>
  );
};

export default Box;
