import React, { useState, useEffect } from 'react';
import { useNavigate, useCookie } from 'react-router-dom'; 
import './Bet_container.css';
import { useCookies } from 'react-cookie';

const translations = {
  en: {
    alerts: '🔔 Alerts',
    filter: 'Filter',
    allPacks: 'All packs',
    freePack: 'Free pack',
    premiumPack: 'Premium pack',
    allStatus: 'All status',
    active: 'Active',
    won: 'Won',
    lost: 'Lost',
    cancelled: 'Cancelled',

  


 
    
  },
  es: {
    alerts: '🔔 Alertas',
    filter: 'Filtrar',
    allPacks: 'Todos los packs',
    freePack: 'Pack gratis',
    premiumPack: 'Pack premium',
    allStatus: 'Todos los estados',
    active: 'Activo',
    won: 'Ganado',
    lost: 'Perdido',
    cancelled: 'Cancelado',

  },
};

const BetBlock = ({ bet, isBlurred, user_data }) => {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  const handleToggleDetails = () => {
    setIsDetailsVisible(!isDetailsVisible);
    if (isBlurred) {
      setIsDetailsVisible(false);
    }
  };

  const getBackgroundColor = () => {
    switch (bet.current_state) {
      case 'Won':
        return 'background-won';
      case 'Lost':
        return 'background-lost';
      case 'Push':
        return 'background-push';
      default:
        return 'background-active';
    }
  };

  const getPackImage = () => {
    if (bet.pack === 'free') {
      return <img src={`${process.env.PUBLIC_URL}/free.png`} alt="Free" />;
    } else if (bet.pack === 'premium') {
      return <img src={`${process.env.PUBLIC_URL}/premium.png`} alt="Premium" />;
    } else {
      return null;
    }
  };

  const getid = () => {
    if (user_data && user_data.user.id) {
      return 'id:' + user_data.user.id;
    }
  };

  return (
    <div className={`bet-block ${isBlurred ? 'blur' : ''}`} onClick={handleToggleDetails}>
      <div className="bet-summary">
        <div className="bet-info">
          <div className="pack-image">{getPackImage()}</div>
          <h2>{bet.bet}</h2>
          <p className="bet-date">{new Date(bet.date).toLocaleString('en-US', { timeZoneName: 'short' })}</p>
        </div>
        <p className="user_id">{(user_data && getid()) || 'no id dksckcnsdncdnlsclbcsnsnndclklcdsnlcnklcncsfdf'}</p>
        <div className={`bet-odds ${getBackgroundColor()}`}>{bet.odds}</div>
        <div className="bet-status">{bet.current_state}</div>
      </div>
      {isDetailsVisible && (
        <div className="bet-details">
          <p>{bet.details}</p>
          <p>{bet.title}</p>
          <p>{bet.sport}</p>
        </div>
      )}
    </div>
  );
};

const BetList_login = ({ data, isBlurred, user_data = undefined }) => {

  const [currentPage, setCurrentPage] = useState(0);
  const [selectedPack, setSelectedPack] = useState('all');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredBets, setFilteredBets] = useState([]);
  const navigate = useNavigate();

  const betsPerPage = 10;

  useEffect(() => {
    try{
    // Update filtered bets whenever data, selectedPack, or selectedStatus changes
    const updatedFilteredBets = data.listings.filter((bet) => {
      if (selectedPack !== 'all' && bet.pack !== selectedPack) {
        return false;
      }
      if (selectedStatus !== 'all' && bet.current_state !== selectedStatus) {
        return false;
      }
      return true;
    });
    setFilteredBets(updatedFilteredBets);
    setCurrentPage(0); // Reset to the first page when filters change
  } catch (error) {}
}, [data, selectedPack, selectedStatus]);

  const totalPages = Math.ceil(filteredBets.length / betsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page - 1);
    window.scrollTo(0, 0);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPageButtonsToShow = 3; // Maximum number of page buttons to display
    const startPage = Math.max(currentPage - Math.floor(maxPageButtonsToShow / 2), 0);
    const endPage = Math.min(startPage + maxPageButtonsToShow, totalPages);

    for (let i = startPage; i < endPage; i++) {
      pageNumbers.push(i + 1);
    }
    return pageNumbers;
  };

  const startIndex = currentPage * betsPerPage;
  const endIndex = startIndex + betsPerPage;
  const visibleBets = filteredBets.slice(startIndex, endIndex);
  const [cookies, setCookie, removeCookie] = useCookies(['betaitoken', 'languageai', 'betairefreshtoken']);
  const language = cookies.languageai || 'en';
  const {alerts, filter, allPacks, freePack, premiumPack, allStatus, active, won, lost, cancelled} =translations[language]|| ['en'];
  return (
    <div className="container">
      <div className="filter-dropdown">
     
      {user_data && user_data.user.is_verified && (
          <button className="alerts-button" onClick={() => navigate('/alerts')}>
            {alerts}
          </button>
        )}


        <button onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          {filter} <span>&#9662;</span>
        </button>
        {isDropdownOpen && (
          <div className="dropdown-content">
            <select
              value={selectedPack}
              onChange={(e) => setSelectedPack(e.target.value)}
            >
              <option value="all">{allPacks}</option>
              <option value="free">{freePack}</option>
              <option value="premium">{premiumPack}</option>
            </select>
            <select
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <option value="all">{allStatus}</option>
              <option value="Active">{active}</option>
              <option value="Won">{won}</option>
              <option value="Lost">{lost}</option>
              <option value="Push">Push</option>
            </select>
          </div>
        )}
      </div>

      <div className="bet-list">
        {visibleBets.map((bet) => (
          <BetBlock key={bet.id} bet={bet} isBlurred={isBlurred} user_data={user_data} />
        ))}
      </div>

      <div className="pagination">
        {currentPage > 0 && (
          <button onClick={() => handlePageClick(currentPage)}>Previous</button>
        )}
        {getPageNumbers().map((page) => (
          <button
            key={page}
            onClick={() => handlePageClick(page)}
            className={currentPage === page - 1 ? 'active' : ''}
          >
            {page}
          </button>
        ))}
        {currentPage < totalPages - 1 && (
          <button onClick={() => handlePageClick(currentPage + 2)}>Next</button>
        )}
      </div>
    </div>
  );
};

export default BetList_login;
