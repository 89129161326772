import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header_2 from '../Header_2';

// Inline styles
const styles = {
  container: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
    maxWidth: '600px',
    margin: '30px auto',
    textAlign: 'center',
    marginTop: '100px',
    paddingTop: '50px',
  },
  header: {
    color: '#2c3e50',
    marginBottom: '15px'
  },
  paragraph: {
    color: '#7f8c8d',
    fontSize: '16px'
  }
};

function Verify() {
  const [cookies] = useCookies(['betaitoken']);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const email = queryParams.get('email');
  const [error, setError] = useState('');

  useEffect(() => {
    if (!code || !email) {
      navigate('/');
      return;
    }

    const verifyUser = async () => {
      try {
        const response = await axios.patch(process.env.REACT_APP_CONFIRM, {
          email,
          code,
        }, {
          headers: {
            Authorization: '',
          },
        });

        if (response.status === 200) {
          setTimeout(() => navigate('/profile'), 3000);
        }
      } catch (err) {
        // Extracting relevant error message details
        const errorMessage = err.response && err.response.data 
          ? `${err.response.data.detail || ''} ${err.response.data.code || ''} ${err.response.data.messages || ''}`
          : 'An unknown error occurred';
        setError(errorMessage);
        setTimeout(() => {
          navigate('/profile');
          window.location.reload();
        }, 3000);
      }
    };

    verifyUser();
  }, [code, email, navigate, cookies.betaitoken]);

  return (
    <div style={styles.container}>
      <Header_2 />
      {error ? (
        <div>
          <h2 style={styles.header}>Error</h2>
          <p style={styles.paragraph}>{error}</p>
          <p style={styles.paragraph}>Redirecting...</p>
        </div>
      ) : (
        <div>
          <h2 style={styles.header}>Verification Successful</h2>
          <p style={styles.paragraph}>Redirecting...</p>
        </div>
      )}
    </div>
  );
}

export default Verify;