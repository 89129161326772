import React from 'react';
import './How.css'; // Make sure to create this CSS file

const translations = {
  en: {
    title: 'How it works?',
    content: 'AI Pickz is the most powerful sports betting tool. We provide you with the best picks and predictions for all major sports using AI. Just follow our prediction in your favorite sportsbook and start winning!',
    step1: 'Step 1.- Create your account',
    step2: 'Step 2.- Receive picks on your app, email and phone',
  },
  es: {
    title: '¿Cómo funciona?',
    content: 'AI Pickz es la herramienta más poderosa para apuestas deportivas. Te proporcionamos los mejores picks y predicciones para todos los deportes principales utilizando inteligencia artificial. ¡Sólo sigue nuestras predicciones en tu casa de apuestas favorita y comienza a ganar!',
    step1: 'Paso 1.- Crea tu cuenta',
    step2: 'Paso 2.- Recibe picks en tu app, correo y teléfono',
  },
};

const How = ({ language }) => {
  const { title, content, step1, step2 } = translations[language] || translations.en;

  return (
    <div className="how">
      <div className="image-content">
        
      </div>
      <div className="content">
        <h1>{title}</h1>
        <p>{content}</p>

        <p> &nbsp; </p>
        <p> &nbsp; </p>

        <section className="steps_section">
        <div className="steps">
          <div className="step">
          
            <img src={`${process.env.PUBLIC_URL}/profile.png`} alt="Profile icon" className="icon" />
            <p className="step-text">{`${step1}`}</p>
          </div>

         <p> &nbsp; </p>
         <p> &nbsp; </p>
          
          <div className="step">
            <img src={`${process.env.PUBLIC_URL}/phone.png`} alt="Phone icon" className="icon" />
            <p className="step-text">{`${step2}`}</p>
          
          
          </div>
         
        </div>
        </section>
      </div>
    </div>
  );
};

export default How;
