import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import './Activate_component.css';

const translations = {
  en: {
    please: 'Please verify your account',
    resend: 'Resend verification email',
    
  },
  es: {
    please: 'Por favor verifica tu cuenta',
    resend: 'Reenviar correo de verificación',
  },
};


const ActivateMessageComponent = ({ user_data, language }) => {
  
  
  const [isClosed, setIsClosed] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [timer, setTimer] = useState(parseInt(localStorage.getItem('verificationTimer')) || 60);
  const [cookies, setCookies] = useCookies(['betaitoken', 'languageai', 'betairefreshtoken']);

  const handleClose = () => {
    setIsClosed(true);
  };

  const handleResend = async () => {
    try {
      // Trigger the verification email
      await sendVerificationEmail(cookies, setCookies);

      // Disable the resend button and start the timer
      setIsResendDisabled(true);
      setTimer(60);
      localStorage.setItem('verificationTimer', 60);
    } catch (error) {
      console.error('Error triggering verification email', error);
      // Handle error appropriately (e.g., display an error message)
    }
  };

  const startTimer = () => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      localStorage.setItem('verificationTimer', timer);
    }, 1000);

    return () => clearInterval(interval);
  };

  useEffect(() => {
    if (timer > 0 && isResendDisabled) {
      const clearTimer = startTimer();
      return clearTimer;
    }
  }, [timer, isResendDisabled]);

  useEffect(() => {
    if (timer === 0) {
      setIsResendDisabled(false);
      localStorage.removeItem('verificationTimer');
    }
  }, [timer]);

  const containerClassName = `user-profile-container_profile ${isClosed ? 'closed' : ''}`;

  if (user_data.user && user_data.user.is_verified) {
    return null; // If user is verified, don't render anything
  }

  const { please, resend} = translations[language] || translations.en;

  return (
    <div className={containerClassName}>
      {!isClosed && (
        <>
          <div className="verification-message_profile">
            <p>{please}</p>
            <button className="resend-button_profile" onClick={handleResend} disabled={isResendDisabled}>
              {resend} {isResendDisabled && `(${timer}s)`}
            </button>
          </div>
          <button className="close-button_profile" onClick={handleClose}>
            &times;
          </button>
        </>
      )}
    </div>
  );
};

const sendVerificationEmail = async (cookies, setCookies) => {
  const token = cookies.betaitoken;
  

  try {
    const response = await axios.get(process.env.REACT_APP_RESEND, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log('Verification email sent successfully', response.data);
  } catch (error) {
    console.error('Error sending verification email', error);
    throw error; // Rethrow the error for the calling function to handle
  }
};

export default ActivateMessageComponent;
