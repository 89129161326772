import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import PricingBox from '../Home_sections/Pricing';
import Header_2 from '../Header_2';
import Header from '../Header';
import VerifyToken from '../../Verify_token';
import './Pricing_page.css';
import Footer from '../Home_sections/Footer';
import LoadingSpinner from '../../LoadingSpinner';
import useUserData from '../services/User_data'; // Update the path as per your folder structure

function Pricing_page() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const prevAccessTokenRef = useRef(false);
  const isTokenValido = useRef(undefined);
  const [cookies, setCookie, removeCookie] = useCookies(['betaitoken', 'languageai']);

  useEffect(() => {
    const verifyToken = async () => {
      await new Promise(resolve => setTimeout(resolve, 0));
      const betairefreshtoken = Cookies.get('betairefreshtoken');
      if (betairefreshtoken) {
        try {
          const isTokenValid = await VerifyToken(betairefreshtoken, Cookies, prevAccessTokenRef);
          isTokenValido.current = isTokenValid;
          setIsLoggedIn(isTokenValido.current);
        } catch (error) {
          console.error("Error verifying token:", error);
          isTokenValido.current = false;
          setIsLoggedIn(isTokenValido.current);
        }
      } else {
        console.error("Missing betairefreshtoken");
        isTokenValido.current = false;
        setIsLoggedIn(isTokenValido.current);
      }
    };

    verifyToken();
  }, []);

  if (!cookies.languageai) {
    const userLang = navigator.language || navigator.userLanguage;
    const maxAgeInSeconds = 604800;
    const preferredLang = userLang === 'es' || userLang === 'en' ? userLang : 'en';
    setCookie('languageai', preferredLang, { path: '/', maxAge: maxAgeInSeconds });
  }

  const language = cookies.languageai || 'en';

  useEffect(() => {
    if (!cookies.betaitoken || !cookies.betairefreshtoken) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, []);

  const { data: user_data, error, isLoading } = useUserData(cookies.betaitoken, isLoggedIn);

  if (error) {
    console.error('Error fetching data:', error);
  }

  return (
    <div className='pricing_in'>
      <main>
        {isLoading ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : (
          <section>
            {isLoggedIn ? <Header_2 user_data={user_data} /> : <Header />}
          </section>
        )}

        <section className='price_container'>
          <PricingBox logged={isLoggedIn} user_data={user_data} language={cookies.languageai} place='log' />
        </section>

        <section>
          <Footer language={language} />
        </section>
      </main>
    </div>
  );
}

export default Pricing_page;
