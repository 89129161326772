import React, { useState } from 'react';
import './User_profile_form.css';


const translations = {
  en: {
    profile: 'Profile',
    verified_trad: 'Verified✅',
    not_verified_trad: 'Not Verified❌',
    name_trad: 'Name',
    lastname_trad: 'Lastname',
    picture_trad: 'Picture URL',
    invalid_url_trad: 'Invalid URL for Profile Picture',
    invalid_name_trad: 'Name and Lastname must be less than 30 characters',
    invalid_url_length_trad: 'URL must be less than 1000 characters',
    save_trad: 'Save',
    edit_trad: 'Edit',
    
    
  },
  es: {
    profile: 'Perfil',
    verified_trad: 'Verificado✅',
    name_trad: 'Nombre',
    lastname_trad: 'Apellido',
    picture_trad: 'URL de imagen',
    invalid_url_trad: 'URL inválida para la imagen de perfil',
    invalid_name_trad: 'Nombre y apellido deben tener menos de 30 caracteres',
    invalid_url_length_trad: 'URL debe tener menos de 1000 caracteres',
    save_trad: 'Guardar',
    edit_trad: 'Editar',
    not_verified_trad: 'No verificado❌',
  },
};


const UserProfileForm = ({ user_data, token, language }) => {
  const { profile, verified_trad,not_verified_trad, name_trad, lastname_trad, picture_trad, invalid_url_trad, invalid_name_trad, invalid_url_length_trad, save_trad, edit_trad } = translations[language] || translations.en;
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    profilePictureUrl: user_data.user.img_url,
    name: user_data.user.name,
    lastName: user_data.user.lastname,
    email: user_data.user.email,
    verified: user_data.user.is_verified,
  });

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };


    const handleSave = async () => {
      // Validate profile picture URL and telephone here before saving
    
      // Example validation for a valid URL
      if (!isValidUrl(formData.profilePictureUrl)) {
        alert(invalid_url_trad);
        return;
      }

      //restrict lenght of name and lastname to max 30  characters each
      if (formData.name.length > 30 || formData.lastName.length > 30) {
        alert(invalid_name_trad);
        return;}

      //restrict lenght of url to max 1000 characters
      if (formData.profilePictureUrl.length > 1000) {
        alert(invalid_url_length_trad);
        return;}


    
      // Prepare the data to be sent in the PATCH request
      const patchData = {
        name: formData.name,
        lastname: formData.lastName,
        img_url: formData.profilePictureUrl,
      };
    
      try {
        // Send a PATCH request to update the user profile
        const response = await fetch(process.env.REACT_APP_ME, {
          method: 'PATCH',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
           
            // Add any additional headers if needed (e.g., authentication token)
          },
          body: JSON.stringify(patchData),
        });
    
        if (response.ok) {
          console.log('Changes saved successfully');
        } else {
          console.error('Failed to save changes:', response.statusText);
          // Handle the error appropriately (show an error message, etc.)
        }
      } catch (error) {
        console.error('An error occurred during the save operation:', error);
        // Handle the error appropriately (show an error message, etc.)
      }
    
      // Disable edit mode after saving
      setEditMode(false);
      //reload page
      window.location.reload();
    };

  const isValidUrl = (url) => {
    try {
      if (url === '') {
        return true;
      }
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <div className="profile_container">
      <form className="profile-form">
        <h1>{profile}</h1>
        <p>{`Email: ${user_data.user.email}`}</p>
        <p style={{ backgroundColor: 'white', color: user_data.user.is_verified ? 'green' : 'red', fontWeight: 700 }}>
  {user_data.user.is_verified ? verified_trad : not_verified_trad}
</p>

        <p> <br>
        </br></p>


        <label className="form-label">{name_trad}</label>
        <input
          type="text"
          className="form-control"
          value={formData.name}
          onChange={(e) => handleInputChange('name', e.target.value)}
          disabled={!editMode}
        />
        <label className="form-label">{lastname_trad}</label>
        <input
          type="text"
          className="form-control"
          value={formData.lastName}
          onChange={(e) => handleInputChange('lastName', e.target.value)}
          disabled={!editMode}
        />
         <label className="form-label">{picture_trad}</label>
        <input
          type="text"
          className="form-control"
          value={formData.profilePictureUrl}
          onChange={(e) => handleInputChange('profilePictureUrl', e.target.value)}
          disabled={!editMode}
        />
        {editMode && (
          <button type="button" className="btn_primary_login" onClick={handleSave}>
            {save_trad}
          </button>
        )}
        {!editMode && (
          <button type="button" className="btn_primary_login" onClick={() => setEditMode(true)}>
            {edit_trad}
          </button>
        )}
      </form>
    </div>
  );
};

export default UserProfileForm;
