import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Select from 'react-select';

import './Header.css';

const Header = ({ isLoggedIn}) => {
  const [cookies, setCookie] = useCookies(['languageai']);
  const location = useLocation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isNavVisible, setIsNavVisible] = useState(screenWidth >= 768);
  const navigate = useNavigate();



  const toggleNav = () => {
    
    if (screenWidth <= 768) {
    setIsNavVisible(!isNavVisible);
  }};

  const handleLanguageChange = (selectedOption) => {
    if (selectedOption) {
      setCookie('languageai', selectedOption.value, { path: '/', maxAge: 604800 });
    }
  };


  const handleLoginClick = () => {
    navigate('/login');
  };

  const languageOptions = [
    { value: 'en', label: '🇺🇸EN' },
    { value: 'es', label: '🇲🇽ES' },
  ];

  const getTranslation = (text) => {
    const translations = {
      en: {
        results: 'Results',
        stories: 'Stories',
        pricing: 'Pricing',
        login: 'Log In ',
        logout: 'Logout',
        works: 'How it works?',
      },
      es: {
        results: 'Resultados',
        stories: 'Historias',
        pricing: 'Precios',
        login: 'Iniciar',
        logout: 'Cerrar',
        works: '¿Cómo funciona?',
      },
    };
    const language = cookies.languageai || 'en';
    return translations[language][text];
  };

  return (
    <header className="header_notlogin">
      <div className="header-content_notlogin">
        <Link to="/" className="logo-container_notlogin" onClick={toggleNav}>
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" />
        </Link>
        <button className="menu-button_notlogin" onClick={toggleNav}>
          Menu
        </button>
        <nav className={`navigation_notlogin ${isNavVisible ? 'visible' : 'hidden'}`} style={{ display: isNavVisible ? 'flex' : 'none' }}>
          <Link to="/#picks" onClick={toggleNav}> Picks</Link>
          <Link to="/#works" onClick={toggleNav}>{getTranslation('works')}</Link>
          <Link to="/#results" onClick={toggleNav}>{getTranslation('results')}</Link>
          <Link to="/#pricing" onClick={toggleNav}>{getTranslation('pricing')}</Link>
          <button className="login-button_notlogin" onClick={handleLoginClick}>
            {getTranslation('login')}
          </button>
          <Select
            className="language-selector_notlogin"
            options={languageOptions}
            onChange={handleLanguageChange}
            value={languageOptions.find((option) => option.value === cookies.languageai)}
          />
        </nav>
      </div>
    </header>
  );
};

export default Header;
