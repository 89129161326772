import React, { useState } from 'react';
import './Bet_container.css';

const BetBlock = ({ bet, isBlurred , user_data}) => {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  

  const handleToggleDetails = () => {
    setIsDetailsVisible(!isDetailsVisible);
    if (isBlurred) {
      setIsDetailsVisible(false);
    }
  };

  const getBackgroundColor = () => {
    switch (bet.current_state) {
      case 'Won':
        return 'background-won'; // Define a CSS class for 'Won' state
      case 'Lost':
        return 'background-lost';
      case 'Push':
        return 'background-push';
      default:
        return 'background-active'; // Define a default CSS class
    }
  };

  const getPackImage = () => {
    if (bet.pack === 'free') {
      return <img src={`${process.env.PUBLIC_URL}/free.png`}   alt="Free" />;
    } else if (bet.pack === 'premium') {
      return <img src={`${process.env.PUBLIC_URL}/premium.png`}  alt="Premium" />;
    } else {
      return null; // No image for other pack values
    }

  };


  const getid = () => {
    if (user_data&& user_data.user.id) {
      
      return 'id:' + user_data.user.id;

    }};


  return (
    <div className={`bet-block ${isBlurred ? 'blur' : ''}`} onClick={handleToggleDetails}>
      <div className="bet-summary">
        <div className="bet-info">
          <div className="pack-image">{getPackImage()}</div>
          <h2>{bet.bet}</h2>
          <p className="bet-date"> {new Date(bet.date).toLocaleString('en-US', { timeZoneName: 'short' })}</p>
        </div>
        <p className="user_id">
  {(user_data && getid()) || ''}
</p>
        <div className={`bet-odds ${getBackgroundColor()}`}>{bet.odds}</div>
        <div className="bet-status">{bet.current_state}</div>
      </div>
      {isDetailsVisible && (
        <div className="bet-details">
          <p>{bet.details}</p>
          <p>{bet.title}</p>
          <p>{bet.sport}</p>
        </div>
      )}
    </div>
  );
};

const BetList = ({ data, isBlurred, user_data = undefined}) => {
  try{
  return (
    <div className="container">
      <div className="bet-list">
        {data &&
          data.listings &&
          Array.isArray(data.listings) &&
          data.listings.map((bet) => (
            <BetBlock key={bet.id} bet={bet} isBlurred={isBlurred} user_data = {user_data} />
          ))}
      </div>
    </div>
  );
}
catch (error) {
  console.error('Error rendering bet list', error);
  return null;
}}
export default BetList;
