import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useCookies } from 'react-cookie';

const Re_direct = () => {
    const [cook, setCookie, removeCookie] = useCookies(['betaitoken', 'languageai', 'betairefreshtoken']);
    const navigate = useNavigate();

    useEffect(() => {
        const Update_token = async () => {
          // Add a delay of 1 second
          await new Promise(resolve => setTimeout(resolve, 100));
          const gbetairefreshtoken = Cookies.get('gbetairefreshtoken');
          if (gbetairefreshtoken) {
            const gbetaitoken = Cookies.get('gbetaitoken');
            const gbetairefreshtoken = Cookies.get('gbetairefreshtoken');
            
            //setting cookies
            setCookie('betaitoken', gbetaitoken, { path: '/', maxAge: 10004800 });
            setCookie('betairefreshtoken', gbetairefreshtoken, { path: '/', maxAge: 10004800 });
            Cookies.remove('gbetaitoken',{ path: '/', domain: '.aipickz.com' });
            Cookies.remove('gbetairefreshtoken', { path: '/', domain: '.aipickz.com' });
            window.location.reload();
        };
    }
    
        Update_token();
        navigate('/');
      }, [navigate]);



    return (
        <div>
            
        </div>
    );
};

export default Re_direct;
