// useActiveData.js
import { useQuery } from 'react-query';
import ApiService from './api_service';

const fetchActiveData = async ({ queryKey }) => {
  const [_, { token, isLoggedIn }] = queryKey;
  const apiService = new ApiService(token);
  let data;
  if (isLoggedIn) {
    data = await apiService.fetchActiveData();
  } else {
    data = await apiService.fetchActiveData(); // Assuming this method is correct even when not logged in
  }
  return data;
};

const useActiveData = (token, isLoggedIn) => {
  return useQuery(['activeData', { token, isLoggedIn }], fetchActiveData, {
    enabled: !!token // Only fetch data if token is present
  });
};

export default useActiveData;
