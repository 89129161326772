import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Results_carrousel.css';

const translations = {
    en: {
      title: 'Results 100% Transparent',
    },
    es: {
      title: 'Resultados 100% Transparentes',
    },
  };

const Results_carrousel = ({ data, language }) => {
    const { title} = translations[language] || translations.en;



  let count = 0;
  if (data && data.listings && Array.isArray(data.listings)) {
      count = data.listings.length;
  }

  const getColor = (details) => {
    const numericValue = parseFloat(details);
    if (numericValue <= 90) {
      return 'gray';
    } else if (numericValue > 90 && numericValue <= 101) {
      return 'black';
    } else if (numericValue > 101 && numericValue <= 110) {
      return '#ececa3';
    } else if (numericValue > 110 && numericValue <= 130) {
      return '#b5e550';
    } else if (numericValue > 130 && numericValue <= 160) {
      return '#809c13';
    } else {
      return 'green';
    }
  };

  return (
    <div className="custom-carousel-container">
      <h1>{title}</h1>
      <Carousel
        showArrows={true}
        showThumbs={false}
        infiniteLoop={false}
        showStatus={false}
        centerMode={true}
        centerSlidePercentage={33.33}
        interval={3000}
        selectedItem={count}
      >
        {data && data.listings && Array.isArray(data.listings) && data.listings.map((item) => (
          <div className="custom-carousel-item" key={item.id}>
            <h2>{item.title}</h2>
            <p style={{ color: getColor(item.details) }}>{`Bank: ${item.details}`}</p>
            
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Results_carrousel;
