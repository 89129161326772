import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './login.css';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import GoogleLoginButton from './GoogleLoginButton';
import onGoogleLoginSuccess from './GoogleLoginButton';
import Header_2 from '../Header_2';
import Header from '../Header';
import VerifyToken from '../../Verify_token';
import Cookies from 'js-cookie';



const translations = {
  en: {
    password_trad: 'Password',
    dont: "Don't have an account?",
    reg: 'Register here',
    or: 'Or',
    incorrect: 'Incorrect email or password.',
    enter_email: 'Enter your email',
    enter_password: 'Enter your password',
    fill_required_fields: 'Please fill in all required fields.',
    passwords_do_not_match: 'Passwords do not match.',
    reset_password: 'Reset password',
    enter_exist: 'Enter your email and we will send you a link to your inbox to reset your password if the account exists',
    send_recovery: 'Send password recovery',
    
  },
  es: {
      password_trad: 'Contraseña',
      dont: 'Aún no tienes una cuenta?',
      reg: 'Regístrate aquí',
      or: 'O',
      incorrect: 'Correo o contraseña incorrectos.',
      enter_email: 'Ingresa tu correo',
      enter_password: 'Ingresa tu contraseña',
      fill_required_fields: 'Por favor llena todos los campos requeridos.',
      passwords_do_not_match: 'Las contraseñas no coinciden.',
      reset_password: 'Restablecer contraseña',
      enter_exist: 'Ingresa tu correo y te enviaremos un enlace a tu bandeja de entrada para restablecer tu contraseña si la cuenta existe',
      send_recovery: 'Enviar recuperación de contraseña',
  },
};

function Forgot() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const prevAccessTokenRef = useRef(false);
  const isTokenValido = useRef(undefined);

  useEffect(() => {
    const verifyToken = async () => {
      // Add a delay of 1 second
      await new Promise(resolve => setTimeout(resolve, 50));
      
      const betairefreshtoken = Cookies.get('betairefreshtoken');
      if (betairefreshtoken) {
        try {
          // Assuming VerifyToken is an asynchronous function that returns a Promise
          const isTokenValid = await VerifyToken(betairefreshtoken, Cookies, prevAccessTokenRef);
          isTokenValido.current = isTokenValid;
          setIsLoggedIn(isTokenValido.current)
        } catch (error) {
          console.error("Error verifying token:", error);
          isTokenValido.current = false;
          setIsLoggedIn(isTokenValido.current)
        }
      } else {
        console.error("Missing betairefreshtoken");
        console.log(isTokenValido.current);
        isTokenValido.current = false;
        setIsLoggedIn(isTokenValido.current)
    
      }
    };

    verifyToken();
    setIsLoggedIn(isTokenValido.current)
  }, [isTokenValido, isLoggedIn]);


  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
      window.location.reload();
    }
  }, [isLoggedIn, navigate, []]);

  


  const [cookies, setCookie, removeCookie] = useCookies(['betaitoken', 'languageai', 'betairefreshtoken']);
  const language = cookies.languageai || 'en';
  const { password_trad, dont, reg, or, incorrect, enter_email, enter_password, send_recovery, enter_exist, reset_password} = translations[language] || translations.en;

  useEffect(() => {
    if (cookies.betaitoken) {
      navigate('/');
      window.location.reload();
    }
  }, [isLoggedIn, navigate, []]);




  

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [sent, setSent] = useState(false);

  const isLoginButtonDisabled = !email 

  const handleLogin = async (e) => {
    e.preventDefault();

    if (email.toLowerCase().endsWith('@gmail.com')) {
      // redirect to https://www.google.com/intl/es/gmail/about/

      navigate('https://www.google.com/intl/es/gmail/about/');
      
      return;
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_PASSWORD,
        JSON.stringify({
          email
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      // wait for 1 second
      setSent(true);
      await new Promise(resolve => setTimeout(resolve, 1000));
      navigate('/login');
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setError('An error occurred. Please try again later.');
      } else if (err.response && err.response.data) {
        setError(err.response.data.message);
      } else {
        setError('An error occurred. Please try again later.');
      }
    }
  };


 

  return (
    <div>
      {isLoggedIn ? <Header_2 /> : <Header />}
      <div className="login-container">
        <form className="login-form" onSubmit={handleLogin} noValidate>
          <h1>{reset_password}</h1>
          <p>{enter_exist}</p>

          <div className="form-group">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="text"
              id="email"
              className="form-control"
              placeholder= {enter_email}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>


          <button type="submit" className="btn_primary_login" disabled={isLoginButtonDisabled}>
            {send_recovery}
          </button>

        
        </form>
      </div>
    </div>
  );
}

export default Forgot;
