import React, { useEffect, useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Header_2 from '../Header_2';

import Header from '../Header';
import Cookies from 'js-cookie';
import VerifyToken from '../../Verify_token';

const translations = {
  en: {
    password_trad: 'Password',
    dont: "Don't have an account?",
    reg: 'Register here',
    or: 'Or',
    incorrect: 'Incorrect email or password.',
    enter_email: 'Enter your email',
    enter_password: 'Enter your password',
    forgot: 'Forgot your password?',
    password_must_have_at_least_8_characters: 'Password must have at least 8 characters',
    password_must_be_less_than_50_characters: 'Password must be less than 50 characters',
    fill_required_fields: 'Please fill in all required fields.',
    passwords_do_not_match: 'Passwords do not match.',
    reset_password: 'Reset password',
    password_succesful: 'Password reset succesful. You will be redirected to the login page in 3 seconds.',
    new_password: 'New password',
    repeat_new_password: 'Repeat new password',
    set_new_password: 'Set new password'
    
  },
  es: {
      password_trad: 'Contraseña',
      dont: 'Aún no tienes una cuenta?',
      reg: 'Regístrate aquí',
      or: 'O',
      incorrect: 'Correo o contraseña incorrectos.',
      enter_email: 'Ingresa tu correo',
      enter_password: 'Ingresa tu contraseña',
      forgot: 'Olvidaste tu contraseña?',
      password_must_have_at_least_8_characters: 'La contraseña debe tener al menos 8 caracteres',
      password_must_be_less_than_50_characters: 'La contraseña debe tener menos de 50 caracteres',
      fill_required_fields: 'Por favor llena todos los campos requeridos.',
      passwords_do_not_match: 'Las contraseñas no coinciden.',
      reset_password: 'Restablecer contraseña',
      password_succesful: 'Restablecimiento de contraseña exitoso. Serás redirigido a la página de inicio de sesión en 3 segundos.',
      new_password: 'Nueva contraseña',
      repeat_new_password: 'Repite nueva contraseña',
      set_new_password: 'Establecer nueva contraseña'

  },
};

// Inline styles
const styles = {
  container: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    padding: '20px',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
    maxWidth: '600px',
    margin: '30px auto',
    textAlign: 'center',
    marginTop: '100px',
    paddingTop: '50px',
  },
  header: {
    color: '#2c3e50',
    marginBottom: '15px'
  },
  paragraph: {
    color: '#7f8c8d',
    fontSize: '16px'
  }
};

function Password_change() {
  const [cookies, setCookie, removeCookie] = useCookies(['betaitoken', 'languageai', 'betairefreshtoken']);
  const language = cookies.languageai || 'en';
  const {passwords_do_not_match, fill_required_fields, password_must_be_less_than_50_characters, password_must_have_at_least_8_characters, enter_password,reset_password, password_succesful, new_password, repeat_new_password, set_new_password} = translations[language] || translations.en;

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const email = queryParams.get('email');
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');

  useEffect(() => {
    if (!code || !email) {
      navigate('/');
      return;
    }
  }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const prevAccessTokenRef = useRef(false);
  const isTokenValido = useRef(undefined);

  useEffect(() => {
    const verifyToken = async () => {
      // Add a delay of 1 second
      await new Promise(resolve => setTimeout(resolve, 50));
      
      const betairefreshtoken = Cookies.get('betairefreshtoken');
      if (betairefreshtoken) {
        try {
          // Assuming VerifyToken is an asynchronous function that returns a Promise
          const isTokenValid = await VerifyToken(betairefreshtoken, Cookies, prevAccessTokenRef);
          isTokenValido.current = isTokenValid;
          setIsLoggedIn(isTokenValido.current)
        } catch (error) {
          console.error("Error verifying token:", error);
          isTokenValido.current = false;
          setIsLoggedIn(isTokenValido.current)
        }
      } else {
        console.error("Missing betairefreshtoken");
        console.log(isTokenValido.current);
        isTokenValido.current = false;
        setIsLoggedIn(isTokenValido.current)
    
      }
    };

    verifyToken();
    setIsLoggedIn(isTokenValido.current)
  }, [isTokenValido, isLoggedIn]);


  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
      window.location.reload();
    }
  }, [isLoggedIn, navigate, []]);

  
  useEffect(() => {
    if (cookies.betaitoken) {
      navigate('/');
      window.location.reload();
    }
  }, [isLoggedIn, navigate, []]);

 
  const [Sent, setSent] = useState(false);

  const isLoginButtonDisabled = !password || !rePassword || password !== rePassword;

  const handleLogin = async (e) => {
    e.preventDefault();

    if (email.toLowerCase().endsWith('@gmail.com')) {
      // redirect to https://www.google.com/intl/es/gmail/about/

      navigate('https://www.google.com/intl/es/gmail/about/');
      
      return;
    }

    if (password !== rePassword) {
      alert(passwords_do_not_match);
      setError(passwords_do_not_match);
      return;
    }

    

    if (!email || !password || !rePassword) {
      alert(fill_required_fields);
      setError(fill_required_fields);
      return;
    }


      //restrict lenght of password to max 50 min 8  characters each  
      if (password.length < 8) {
        alert(password_must_have_at_least_8_characters);
        setError(password_must_have_at_least_8_characters);
        return;}

      if (password.length > 50) {
        alert(password_must_be_less_than_50_characters);
        setError(password_must_be_less_than_50_characters);
        return;}



    try {
      const response = await axios.patch(
        process.env.REACT_APP_PASSWORD,
        JSON.stringify({
          email,
          code,
          password,

        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      // wait for 1 second
      setSent(true);
      await new Promise(resolve => setTimeout(resolve, 3000));
      navigate('/login');
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setError('An error occurred. Please try again later.');
      } else if (err.response && err.response.data) {
        setError(err.response.data.message);
      } else {
        setError('An error occurred. Please try again later.');
      }
    }
  };


  

  return (
    <div>
      {isLoggedIn ? <Header_2 /> : <Header />}
      <div className="login-container">
        <form className="login-form" onSubmit={handleLogin} noValidate>
          {Sent ? (
           <h1 style="color: darkgreen; font-weight: 500; font-size: 30px;">{password_succesful}</h1>
) : (
            <h1>{reset_password}</h1>
          )}
  
          <div className="form-group">
            <label htmlFor="email" className="form-label">
              {new_password}
            </label>
            <input
              type="password"
              id="password"
              className="form-control"
              placeholder={enter_password}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
  
          <div className="form-group">
            <label htmlFor="email" className="form-label">
              {repeat_new_password}
            </label>
            <input
              type="password"
              id="rePassword"
              className="form-control"
              placeholder={enter_password}
              value={rePassword}
              onChange={(e) => setRePassword(e.target.value)}
              required
            />
          </div>
  
          {error && (
            <p className="error-message" style={{ color: 'red' }}>
              {error}
            </p>
          )}
  
          <button type="submit" className="btn_primary_login" disabled={isLoginButtonDisabled}>
            {set_new_password}
          </button>
        </form>
      </div>
    </div>
  );
  
}

export default Password_change;
