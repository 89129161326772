import React from 'react';
import './Footer.css'; // Link to your CSS file


const translations = {
    en: {
      news: 'News',
      terms: 'Terms and Conditions',
      privacy: 'Privacy Statement',
      
    },
    es: {
        news: 'Noticias',
        terms: 'Términos y Condiciones',
        privacy: 'Aviso de Privacidad',
    },
  };

function Footer({language}) {
    const { news, terms, privacy} = translations[language] || translations.en;
  return (
    <footer className="footer">
      <div className="footer-section">
     
      </div>
      <div className="footer-section connect">
       
      <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">{news}</a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
        <a href="https://telegram.org" target="_blank" rel="noopener noreferrer">Telegram</a>
        <a href="https://whatsapp.com" target="_blank" rel="noopener noreferrer">WhatsApp</a>
        <a href="/terms-and-conditions">{terms}</a>
        <a href="/privacy-statement">{privacy}</a>
        <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Descriptive text for image" />
        
      </div>
      
    </footer>
  );
}

export default Footer;
