import React, { useState, useEffect } from "react";
import "./Profile_menu.css";
import Select from 'react-select';
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';

const translations = {
  en: {
    profile: 'Profile',
    alerts: 'Alerts',
    subscriptions: 'Subscriptions',
    help: 'Help',
    news: 'News',
    upgrade: 'Upgrade',
  },
  es: {
    profile: 'Perfil',
    alerts: 'Alertas',
    subscriptions: 'Subscripciones',
    help: 'Ayuda',
    news: 'Noticias',
    upgrade: 'Mejorar',
  },
};

function Profile_menu(user_data) {
  const [menuVisible, setMenuVisible] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['languageai', 'betaitoken']);
  const [languageChanged, setLanguageChanged] = useState(false);


      
  // check is there is a cookies.languageai, if not, set try to get language preference from browser
  if (!cookies.languageai) {
    const userLang = navigator.language || navigator.userLanguage;
    const maxAgeInSeconds = 604800; // 7 days in seconds
  
    // Check if the user's language is 'es' or 'en'; otherwise, set it to English ('en')
    const preferredLang = userLang === 'es' || userLang === 'en' ? userLang : 'en';
  
    setCookie('languageai', preferredLang, { path: '/', maxAge: maxAgeInSeconds });
  };




  useEffect(() => {
    // Check if language has changed
    if (languageChanged) {
      // Perform PATCH request to update language on the server
      const updateUserLanguage = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_LANGUAGE, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${cookies.betaitoken}`
            },
            body: JSON.stringify({ language: cookies.languageai })
          });

          // Check if the request was successful
          if (response.ok) {
            console.log('Language updated successfully!');
          } else {
            console.error('Failed to update language.');
          }
        } catch (error) {
          console.error('Error updating language:', error);
        }
      };

      // Call the function to update language
      updateUserLanguage();

      // Reset the languageChanged state to false
      setLanguageChanged(false);
    }
  }, [languageChanged, cookies.languageai, cookies.betaitoken]);

  const languageOptions = [
    { value: 'en', label: '🇺🇸EN' },
    { value: 'es', label: '🇲🇽ES' },
  ];

  const menuToggle = () => {
    setMenuVisible(!menuVisible);
  };

  const language = cookies.languageai || 'en';

  const { profile, alerts, subscriptions, help, news, upgrade } = translations[language] || translations.en;

  const handleLanguageChange = (selectedOption) => {
    const selectedLanguage = selectedOption.value;
    setCookie('languageai', selectedLanguage, { path: '/' });
    setLanguageChanged(true);
  };

  const handleLogout = () => {
    removeCookie('betaitoken', { path: '/' });
    removeCookie('betairefreshtoken', { path: '/' });
    Cookies.remove('gbetaitoken',{ path: '/', domain: '.aipickz.com' });
    Cookies.remove('gbetairefreshtoken', { path: '/', domain: '.aipickz.com' });
    Cookies.remove('gbetaitoken',{ path: '/', domain: 'aipickz.com' });
    Cookies.remove('gbetairefreshtoken', { path: '/', domain: 'aipickz.com' });
    Cookies.remove('betaitoken',{ path: '/', domain: '.aipickz.com' });
    Cookies.remove('betairefreshtoken', { path: '/', domain: '.aipickz.com' });
    Cookies.remove('betaitoken',{ path: '/', domain: 'aipickz.com' });
    Cookies.remove('betairefreshtoken', { path: '/', domain: 'aipickz.com' });
    window.location.reload();
  };

  const getPackImage = () => {
    if (user_data.user_data.user_data) {
      if (user_data.user_data.user_data.user.subscription_type === 'free') {
        return (
          <>
            <img src={`${process.env.PUBLIC_URL}/free.png`} alt="Free" />
            <button className = 'upgrade' onClick={() => window.location.href = "/pricing"}>{upgrade}</button>
          </>
        );
      } else if (user_data.user_data.user_data.user.subscription_type === 'premium') {
        return <img src={`${process.env.PUBLIC_URL}/premium.png`} alt="Premium" />;
      } else {
        return <img src={`${process.env.PUBLIC_URL}/free.png`} alt="Free" />;
      }
    } else {
      return <img src={`${process.env.PUBLIC_URL}/free.png`} alt="Free" />;
    }
  };

  return (
    <div className="action">
      <div className="profile" onClick={menuToggle}>
        <img src={(user_data.user_data.user_data && user_data.user_data.user_data.user.img_url) || 'blank.png'} referrerpolicy="no-referrer" />
      </div>
      <div className={`menu ${menuVisible ? "active" : ""}`}>
        <div className="pack-image_menu"> {getPackImage()}</div>
        <h3>
          {(user_data.user_data.user_data && user_data.user_data.user_data.user.name) || 'User'}<br />
          <span>{(user_data.user_data.user_data && user_data.user_data.user_data.user.email) || 'email'}</span>
        </h3>
        <ul>
          <li>
            <a href="/profile">{profile}</a>
          </li>
          <li>
            <a href="#">{alerts}</a>
          </li>
          <li>
            <a href="#">{subscriptions}</a>
          </li>
          <li>
            <a href="#">{help}</a>
          </li>
          <li>
            <a href="/" onClick={handleLogout}>Logout</a>
          </li>
          <li>
            <Select
              className="language-selector"
              options={languageOptions}
              onChange={handleLanguageChange}
              value={languageOptions.find((option) => option.value === language)}
            />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Profile_menu;
