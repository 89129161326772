// customHooks.js
import { useEffect, useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';

const VerifyToken = async (refresh, setCookie, prevAccessTokenRef) => {
  try {

    
    // Service to refresh token
    const response = await fetch( process.env.REACT_APP_REFRESH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh }),
    });

    if (response.status === 200) {
      const data = await response.json();
      Cookies.remove('betaitoken');
      Cookies.remove('betairefreshtoken');
      Cookies.set('betaitoken', data.access, { path: '/', expires: 36009090 });
      Cookies.set('betairefreshtoken', data.refresh, { path: '/', expires: 36009 });
      
       // Store the new access token for later use
       prevAccessTokenRef.current = data.access;
    } else {
      // Token is not valid
      // Handle this case as needed
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    return false; // Error occurred, consider the token invalid
    // Error occurred, consider the token invalid
    // Handle this case as needed
  }

  try {
    
    // Verify the token
    const response = await fetch(process.env.REACT_APP_VERIFY, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: prevAccessTokenRef.current }),
    });



    
    if (response.status === 200) {
      
      return true; // Token is valid
     
    } else {
      return false; // Token is not valid
    }
  } catch (error) {
    console.error('Error verifying token:', error);
    return false; // Error occurred, consider the token invalid
  }
};



export default VerifyToken;
