import React from 'react'
import axios from 'axios';




class ApiService {
  constructor(token) {
    this.token = token;
    this.axiosConfig = {
      headers: {
        'Authorization': `TOKEN ${this.token}`,
        'Content-Type': 'application/json',
      },
    };

    this.no_axiosConfig = {
      headers: {
        'Authorization': '',
        'Content-Type': 'application/json',
      },
    };
  

  this.bearer_axiosConfig = {
    headers: {
      'Authorization': `Bearer ${this.token}`,
      'Content-Type': 'application/json',
    },
  };
}

  

  async fetchFreeHist() {
    try {
      const response = await axios.get(process.env.REACT_APP_FREE_HIST , this.axiosConfig);
     
      return response.data;
    } catch (error) {
      console.error('Error fetching FreeHist data:', error);
      throw error;
    }
  }

  
  async fetchFreeHistNoAuth() {
    try {
      const response = await axios.get(process.env.REACT_APP_FREE_HIST, this.no_axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching FreeHist data:', error);
      throw error;
    }
  }

  async fetchCount() {
    try {
      const response = await axios.get(process.env.REACT_APP_COUNT , this.axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching FreeHist data:', error);
      throw error;
    }
  }

  async fetchCountNoAuth() {
    try {
      const response = await axios.get(process.env.REACT_APP_COUNT, this.no_axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching FreeHist data:', error);
      throw error;
    }
  }

  async fetchFreeActive() {
    try {
      const response = await axios.get(process.env.REACT_APP_FREE_ACT, this.axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching FreeActive data:', error);
      throw error;
    }
  }



  async fetchFreeFake() {
    try {
      const response = await axios.get(process.env.REACT_APP_DUMMY, this.axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching FreeHist data:', error);
      throw error;
    }
  }

  async fetchFreeFakeNoAuth() {
    try {
      const response = await axios.get(process.env.REACT_APP_DUMMY, this.no_axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching FreeHist data:', error);
      throw error;
    }
  }


  async fetchPacksResults() {
    try {
      const response = await axios.get(process.env.REACT_APP_RESULTS, this.axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching FreeHist data:', error);
      throw error;
    }
  }

  async fetchPacksResultsNoAuth() {
    try {
      const response = await axios.get(process.env.REACT_APP_RESULTS, this.no_axiosConfig);
      return response.data;
    } catch (error) {
      console.error('Error fetching FreeHist data:', error);
      throw error;
    }
  }


  async fetchUserData() {
    try {
      const response = await axios.get(process.env.REACT_APP_ME, this.bearer_axiosConfig);
      return response.data;
    } catch (error) {
      if (error.response) {
        // If there is a response from the server (even if it's an error response), return an empty string or any other desired value.
        return 'error'; // You can replace '' with your desired string value.
      } else {
        // If there is no response from the server (e.g., network error), handle the error as before.
        console.error('Error fetching FreeHist data:', error);
        throw error;
      }
    }
  }



  async fetchActiveData() {
    try {
      const response = await axios.get(process.env.REACT_APP_ACTIVE, this.bearer_axiosConfig);
      return response.data;
    } catch (error) {
      if (error.response) {
        // If there is a response from the server (even if it's an error response), return an empty string or any other desired value.
        return 'error'; // You can replace '' with your desired string value.
      } else {
        // If there is no response from the server (e.g., network error), handle the error as before.
        console.error('Error fetching FreeHist data:', error);
        throw error;
      }
    }
  }



  async fetchPastData() {
    try {
      const response = await axios.get(process.env.REACT_APP_PAST, this.bearer_axiosConfig);
      return response.data;
    } catch (error) {
      if (error.response) {
        // If there is a response from the server (even if it's an error response), return an empty string or any other desired value.
        return 'error'; // You can replace '' with your desired string value.
      } else {
        // If there is no response from the server (e.g., network error), handle the error as before.
        console.error('Error fetching FreeHist data:', error);
        throw error;
      }
    }
  }




}

export default ApiService;
