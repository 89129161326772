import React, { useState } from 'react';
import './Carousel.css';


const translations = {
  en: {
    active_picks: 'Active Picks',
    past_picks: 'Past Picks',
  },
  es: {
    active_picks: 'Picks Activos',
    past_picks: 'Picks Pasados',
  },
};




const Carousel = ({ items, language }) => {
  const { active_picks, past_picks } = translations[language] || translations.en;
  const [currentSlide, setCurrentSlide] = useState(0);

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="carousel">
      <div className="carousel-nav">
        <div
          className={`carousel-nav-item ${
            currentSlide === 0 ? 'active' : ''
          }`}
          onClick={(e) => {
            e.preventDefault();
            goToSlide(0);
          }}
        >
          <a>{past_picks}</a>
        </div>
        <div
          className={`carousel-nav-item ${
            currentSlide === 1 ? 'active' : ''
          }`}
          onClick={(e) => {
            e.preventDefault();
            goToSlide(1);
          }}
        >
          <a>{active_picks}</a>
        </div>
      </div>
      <div className="carousel-container">
        {items.map((item, index) => (
          <div
            key={index}
            className={`carousel-item ${
              index === currentSlide ? 'active' : ''
            }`}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
