import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { QueryClient, QueryClientProvider } from 'react-query';

import App from './App';
import Login from './components/login_logout_register/login';
import Register from './components/login_logout_register/register';
import Verify from './components/login_logout_register/Verify';
import Pricing_page from './components/pages/Pricing_page';
import News from './components/pages/News';
import Ppage from './components/pages/Profile_page';
import Re_direct from './components/pages/G_redirect';
import Password_change from './components/login_logout_register/Password';
import Forgot from './components/login_logout_register/Forgot';

import './global.css';

const queryClient = new QueryClient();

const RouteSpecificMeta = ({ title, description }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta property="og:image" content={`${process.env.PUBLIC_URL}/logo.png`} />
  </Helmet>
);

// to indicate paths by components or apps
function Router() {
  return (
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <RouteSpecificMeta title="Home" description="AI Pickz is a powerful sports betting tool providing the best picks and predictions for all major sports using AI. Follow our predictions in your favorite sportsbook and start winning!" />
                <App />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <RouteSpecificMeta title="Login" description="Login to AI Pickz app and start winning!" />
                <Login />
              </>
            }
          />
          <Route
            path="/redirect"
            element={
              <>
                <RouteSpecificMeta title="Redirect" description="Login to AI Pickz app and start winning!" />
                <Re_direct />
              </>
            }
          />
          <Route
            path="/register"
            element={
              <>
                <RouteSpecificMeta title="Register" description="Register to AI Pickz app and start winning!" />
                <Register />
              </>
            }
          />
          <Route
            path="/verify"
            element={
              <>
                <RouteSpecificMeta title="Verify" description="Verify your account with AI Pickz!" />
                <Verify />
              </>
            }
          />
          <Route
            path="/password"
            element={
              <>
                <RouteSpecificMeta title="Password" description="Change your account password" />
                <Password_change />
              </>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <>
                <RouteSpecificMeta title="Forgot" description="Forgot your password?" />
                <Forgot />
              </>
            }
          />
          <Route
            path="/pricing"
            element={
              <>
                <RouteSpecificMeta title="Pricing" description="AI Pickz pricing plans. Choose your picks plan." />
                <Pricing_page />
              </>
            }
          />
          <Route
            path="/news"
            element={
              <>
                <RouteSpecificMeta title="News" description="The latest news about our picks." />
                <News />
              </>
            }
          />
          <Route
            path="/profile"
            element={
              <>
                <RouteSpecificMeta title="Profile" description="Manage your AI Pickz profile details." />
                <Ppage />
              </>
            }
          />
          {/* Redirect any unknown routes to Home */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </CookiesProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
