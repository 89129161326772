import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import './register.css';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import Header_2 from '../Header_2';
import Header from '../Header';
import VerifyToken from '../../Verify_token';



const translations = {
  en: {
    password_trad: 'Password',
    dont: "Don't have an account?",
    reg: 'Register',
    or: 'Or',
    incorrect: 'Incorrect email or password.',
    enter_email: 'Enter your email',
    enter_password: 'Enter your password',
    name_trad: 'Name',
    last_name_trad: 'Last Name',
    confirm_password: 'Confirm Password',
    accept_term_trad: 'I have read and accept the terms and conditions',
    fill_required_fields: 'Please fill in all required fields.',
    passwords_do_not_match: 'Passwords do not match.',
    name_trad_placeholder: 'Enter your name',
    last_name_trad_placeholder: 'Enter your lastname',
    email_placeholder: 'Enter your email',
    confirm_password_trad: 'Confirm your password',
    terms: 'please read and accept the terms and conditions to proceed',
    must_be_less_than_30_characters: 'Name and Lastname must be less than 30 characters',
    must_be_less_than_50_characters: 'Email must be less than 50 characters',
    password_must_have_at_least_8_characters: 'Password must have at least 8 characters',
    password_must_be_less_than_50_characters: 'Password must be less than 50 characters'

  },
  es: {
    password_trad: 'Contraseña',
    dont: 'Aún no tienes una cuenta?',
    reg: 'Registro',
    or: 'O',
    incorrect: 'Correo o contraseña incorrectos.',
    enter_email: 'Ingresa tu correo',
    enter_password: 'Ingresa tu contraseña',
    name_trad: 'Nombre',
    last_name_trad: 'Apellido',
    confirm_password: 'Confirmar Contraseña',
    accept_terms_trad: 'He leído y acepto los términos y condiciones',
    fill_required_fields: 'Por favor completa todos los campos requeridos.',
    passwords_do_not_match: 'Las contraseñas no coinciden.',
    name_trad_placeholder: 'Ingresa tu nombre',
    last_name_trad_placeholder: 'Ingresa tu apellido',
    email_placeholder: 'Ingresa tu correo',
    confirm_password_trad: 'Confirma tu contraseña',
    terms: 'Por favor lee y acepta los términos y condiciones para continuar',
    must_be_less_than_30_characters: 'Nombre y apellido deben tener menos de 30 caracteres',
    must_be_less_than_50_characters: 'El correo debe tener menos de 50 caracteres',
    password_must_have_at_least_8_characters: 'La contraseña debe tener al menos 8 caracteres',
    password_must_be_less_than_50_characters: 'La contraseña debe tener menos de 50 caracteres',
  },
};


function Register() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const prevAccessTokenRef = useRef(false);
  const isTokenValido = useRef(undefined);

  useEffect(() => {
    const verifyToken = async () => {
      // Add a delay of 1 second
      await new Promise(resolve => setTimeout(resolve, 50));
      
      const betairefreshtoken = Cookies.get('betairefreshtoken');
      if (betairefreshtoken) {
        try {
          // Assuming VerifyToken is an asynchronous function that returns a Promise
          const isTokenValid = await VerifyToken(betairefreshtoken, Cookies, prevAccessTokenRef);
          isTokenValido.current = isTokenValid;
          setIsLoggedIn(isTokenValido.current)
        } catch (error) {
          console.error("Error verifying token:", error);
          isTokenValido.current = false;
          setIsLoggedIn(isTokenValido.current)
        }
      } else {
        console.error("Missing betairefreshtoken");
        console.log(isTokenValido.current);
        isTokenValido.current = false;
        setIsLoggedIn(isTokenValido.current)
    
      }
    };

    verifyToken();
    setIsLoggedIn(isTokenValido.current)
  }, [isTokenValido, isLoggedIn]);


  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
      window.location.reload();
    }
  }, [isLoggedIn, navigate, []]);


  const [cookies, setCookie, removeCookie] = useCookies(['betaitoken', 'languageai', 'betairefreshtoken']);

  useEffect(() => {
    if (cookies.betaitoken) {
      navigate('/');
      window.location.reload();
    }
  }, [isLoggedIn, navigate, []]);



  if (!cookies.languageai) {
    const userLang = navigator.language || navigator.userLanguage;
    const maxAgeInSeconds = 10004800; 
  
    // Check if the user's language is 'es' or 'en'; otherwise, set it to English ('en')
    const preferredLang = userLang === 'es' || userLang === 'en' ? userLang : 'en';
  
    setCookie('languageai', preferredLang, { path: '/', maxAge: maxAgeInSeconds });
  };




  const language = cookies.languageai || 'en';

  const { name_trad,last_name_trad,last_name_trad_placeholder, name_trad_placeholder,  reg, email_placeholder, password_trad, enter_password, confirm_password_trad, terms, must_be_less_than_30_characters, must_be_less_than_50_characters, password_must_have_at_least_8_characters, password_must_be_less_than_50_characters, passwords_do_not_match, accept_terms_trad, fill_required_fields} = translations[language] || translations.en;




  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [error, setError] = useState('');
  const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  const apiBaseURL = "https://api.aipickz.com"
 ;

// Function to send verification email
const sendVerificationEmail = async (token) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_SEND_VERIFICATION_EMAIL,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Verification email sent successfully", response.data);
  } catch (error) {
    console.error("Error sending verification email", error);
  }
};

  const handleRegister = async (e) => {
    e.preventDefault();

    if (email.toLowerCase().endsWith('@gmail.com')) {
      const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
      const redirectUri = 'api/v1/auth/login/google/';
      const scope = [
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile'
      ].join(' ');

      const params = {
        response_type: 'code',
        client_id: googleClientId,
        redirect_uri: `${apiBaseURL}/${redirectUri}`,
        prompt: 'select_account',
        access_type: 'offline',
        scope
      };

      const urlParams = new URLSearchParams(params).toString();
      window.location = `${googleAuthUrl}?${urlParams}`;
      return;
    }

    if (password !== rePassword) {
      alert(passwords_do_not_match);
      setError(passwords_do_not_match);
      return;
    }

    if (!acceptTerms) {
      alert(terms);
      setError(terms);
      return;
    }

    if (!email || !password || !rePassword) {
      alert(fill_required_fields);
      setError(fill_required_fields);
      return;
    }

      //restrict lenght of name and lastname to max 30  characters each
      if (name.length > 30 || lastname.length > 30) {
        alert(must_be_less_than_30_characters);
        setError(must_be_less_than_30_characters);
        return;}

       //restrict lenght of email to max 50  characters each
        if (email.length > 50) {
          alert(must_be_less_than_50_characters);
          setError(must_be_less_than_50_characters);
          return;}

      //restrict lenght of password to max 50 min 8  characters each  
      if (password.length < 8) {
        alert(password_must_have_at_least_8_characters);
        setError(password_must_have_at_least_8_characters);
        return;}

      if (password.length > 50) {
        alert(password_must_be_less_than_50_characters);
        setError(password_must_be_less_than_50_characters);
        return;}

    try {
      const response = await axios.post(
        process.env.REACT_APP_REGISTER,
        {
          name: name,
          lastname: lastname,
          email: email,
          password: password,
          re_password: rePassword,
          language: language,
        }
      );

      // login
      const loginResponse = await axios.post(
        process.env.REACT_APP_LOGIN,
        JSON.stringify({
          email,
          password,
        }),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      setCookie('betaitoken', loginResponse.data.access);
      setCookie('betairefreshtoken', loginResponse.data.refresh);
      setIsLoggedIn(true);


      // send verification email
      await sendVerificationEmail(loginResponse.data.access);

     




      navigate('/');
    } catch (err) {
      setError(err.response.data.error);
    }
  };


  return (
    <div>
      {isLoggedIn ? <Header_2 /> : <Header />}

      <div className="login-container_reg">
        <form className="login-form" onSubmit={handleRegister} Validate>
          <h1>{reg}</h1>

          <div className="form-group">
            <label htmlFor="name" className="form-label">
              {name_trad}<span className="required-legend"></span>
            </label>
            <input
              type="text"
              id="name"
              className="form-control"
              placeholder={name_trad_placeholder}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="lastname" className="form-label">
              {last_name_trad}<span className="required-legend"></span>
            </label>
            <input
              type="text"
              id="lastname"
              className="form-control"
              placeholder= {last_name_trad_placeholder}
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email" className="form-label">
              Email<span className="required-legend">*</span>
            </label>
            <input
              type="email"
              id="email"
              className="form-control"
              placeholder={email_placeholder}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="password" className="form-label">
              {password_trad}<span className="required-legend">*</span>
            </label>
            <input
              type="password"
              id="password"
              className="form-control"
              placeholder={enter_password}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="rePassword" className="form-label">
              {confirm_password_trad}<span className="required-legend">*</span>
            </label>
            <input
              type="password"
              id="rePassword"
              className="form-control"
              placeholder={enter_password}
              value={rePassword}
              onChange={(e) => setRePassword(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <input
              type="checkbox"
              id="acceptTerms"
              checked={acceptTerms}
              onChange={() => setAcceptTerms(!acceptTerms)}
              required
            />
            <label htmlFor="acceptTerms" className="checkbox-label">
              {terms}
              <span className="required-legend">*</span>
            </label>
          </div>

          {(error) && (
            <p className="error-message" style={{ color: 'red' }}>
              {error}
            </p>
          )}

          <button
            type="submit"
            className="btn_primary_login"
          >
            {reg}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Register;
