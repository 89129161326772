import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import Cookies from 'js-cookie';
import Logout from './components/login_logout_register/logout';
import Header from './components/Header';
import Header_2 from './components/Header_2';
import BetList from './components/bet_containers/Bet_container';
import BetList_login from './components/bet_containers/Bet_container_login';
import Carousel from './components/bet_containers/Carousel';
import Carousel_login from './components/bet_containers/Carousel_login';
import How from './components/Home_sections/How';
import Results_carrousel from './components/Home_sections/Results_carrousel';
import Testimonials from './components/Home_sections/Testimonials';
import PricingBox from './components/Home_sections/Pricing';
import Footer from './components/Home_sections/Footer';
import { Element, scroller } from 'react-scroll';
import VerifyToken from './Verify_token';
import LoadingSpinner from './LoadingSpinner';
import ActivateMessage from './components/Home_sections/ActivateMessage';
import ActivatePrem from './components/Home_sections/ActivatePremium';
import Count_box from './components/Home_sections/Count';
import './App.css';
import useFreeHistData from './components/services/No_login_data';
import useFakeData from './components/services/Fake_data';
import useBetCountData from './components/services/Bet_count';
import usePackResultsData from './components/services/Pack_results';
import useUserData from './components/services/User_data';
import useActiveData from './components/services/Active_data';
import usePastData from './components/services/Past_data';




function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  const prevAccessTokenRef = useRef(false);
  const isTokenValido = useRef(undefined);
  const effectRan = useRef(false);

  useEffect(() => {
    const verifyToken = async () => {
      await new Promise(resolve => setTimeout(resolve, 0));
      const betairefreshtoken = Cookies.get('betairefreshtoken');
      if (betairefreshtoken) {
        try {
          const isTokenValid = await VerifyToken(betairefreshtoken, Cookies, prevAccessTokenRef);
          isTokenValido.current = isTokenValid;
          setIsLoggedIn(isTokenValido.current);
        } catch (error) {
          console.error("Error verifying token:", error);
          isTokenValido.current = false;
          setIsLoggedIn(isTokenValido.current);
        }
      } else {
        console.error("Missing betairefreshtoken");
        isTokenValido.current = false;
        setIsLoggedIn(isTokenValido.current);
      }
    };

    verifyToken();
  }, [isTokenValido, isLoggedIn]);

  const [cookies, setCookie] = useCookies(['betaitoken', 'languageai', 'betairefreshtoken']);
  const [isLoading, setIsLoading] = useState(true);

  if (!cookies.languageai) {
    const userLang = navigator.language || navigator.userLanguage;
    const maxAgeInSeconds = 10004800;
    const preferredLang = userLang === 'es' || userLang === 'en' ? userLang : 'en';
    setCookie('languageai', preferredLang, { path: '/', maxAge: maxAgeInSeconds });
  }

  const navigate = useNavigate();
  const location = useLocation();

  const scrollToSection = (hash) => {
    const validSections = ["picks", "works", "results", "pricing"];
    if (hash && validSections.includes(hash)) {
      scroller.scrollTo(hash, {
        duration: 300,
        smooth: 'easeInOutQuart',
        offset: -100,
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    if (!isLoading) {
      scrollToSection(hash);
    }
  }, [location.pathname, location.hash, isLoading]);


  const { data: data_free_hist, isLoading: loadingFreeHist } = useFreeHistData(cookies.betaitoken, isLoggedIn);
  const { data: data_fake, isLoading: loadingFake } = useFakeData(cookies.betaitoken, isLoggedIn);
  const { data: data_results, isLoading: loadingResults } = usePackResultsData(cookies.betaitoken, isLoggedIn);
  const { data: user_data, isLoading: loadingUserData } = useUserData(cookies.betaitoken, isLoggedIn);
  const { data: active_data, isLoading: loadingActiveData } = useActiveData(cookies.betaitoken, isLoggedIn);
  const { data: past_data, isLoading: loadingPastData } = usePastData(cookies.betaitoken, isLoggedIn);
  const { data: bet_count, isLoading: loadingBetCount } = useBetCountData(cookies.betaitoken, isLoggedIn);

  useEffect(() => {
    if (
      !loadingFreeHist &&
      !loadingFake &&
      !loadingResults &&
      !loadingBetCount &&
      isLoggedIn !== false
    ) {
      setIsLoading(false);
    }
  }, 
  [
    loadingFreeHist,
    loadingFake,
    loadingResults,
    loadingBetCount,
    isLoggedIn,
  ]);

  useEffect(() => {
    if (
      !loadingUserData &&
      !loadingActiveData &&
      !loadingPastData &&
      !loadingBetCount &&
      isLoggedIn !== true
    ) {
      setIsLoading(false);
    }
  }, 
  [
    loadingUserData,
    loadingActiveData,
    loadingPastData,
    loadingBetCount,
    isLoggedIn,
  ]);



  const handleLoginClick = () => {
    navigate('/login');
  };

  const language = cookies.languageai || 'en';

  const getTranslation = (text) => {
    const translations = {
      en: {
        loginMessage: 'Please log in to view picks.',
        loggedInMessage: 'You are logged in.',
        winWithAI: 'Win with AI',
        startFree: 'Start 100% free',
        login: 'Login',
        poweredBets: 'powered bets',
      },
      es: {
        loginMessage: 'Por favor, inicia sesión para ver picks.',
        loggedInMessage: 'Estás conectado.',
        winWithAI: 'Gana con apuestas',
        startFree: 'Comienza 100% gratis',
        login: 'Iniciar sesión',
        poweredBets: 'potenciadas por IA',
      },
    };

    return translations[language][text];
  };

  const items = [
    <div key="betlist_free_hist">
      <BetList data={data_free_hist} isBlurred={false} />
    </div>,
    <div key="message_container" className="message_cont">
      <p>{getTranslation('loginMessage')}</p>
      <button className='login-button_2' onClick={handleLoginClick}>Login</button>
      <BetList data={data_fake} isBlurred={true} />
    </div>,
  ];

  const items_login = [
    <div key="betlist_free_hist">
      <BetList_login data={active_data} isBlurred={false} user_data={user_data} />
    </div>,
    <div key="betlist_free_hist">
      <BetList_login data={past_data} isBlurred={false} user_data={user_data} />
    </div>,
  ];

  return (
      <div className="App">
        <main>
          {isLoading ? (
            <div>
              <LoadingSpinner />
            </div>
          ) : isLoggedIn ? (
            <>
              <section>
                <Header_2 user_data={user_data} />
              </section>
              <section className='activate_message'>
                <ActivateMessage user_data={user_data} language={language} />
              </section>
              <section className='count_message'>
                <Count_box user_data={user_data} language={language} bet_count={bet_count} />
              </section>
              <section className='make_premium_message'>
                <ActivatePrem user_data={user_data} language={language} />
              </section>
              <section className='picks_section'>
                <Carousel_login items={items_login} language={language} />
              </section>
              <section>
                <Footer language={language} />
              </section>
            </>
          ) : (
            <>
              <Header />
              <section className="banner">
                <h1 className="banner_text">
                  {getTranslation('winWithAI')} <br />
                  {getTranslation('poweredBets')}
                </h1>
                <button className="cta-button" onClick={handleLoginClick}>
                  {getTranslation('startFree')}
                </button>
              </section>
              <Element name="picks">
                <section className="features">
                  <Carousel items={items} language={language} />
                  <div key="coming_soon_container">
                    {isLoggedIn ? (
                      <>
                        <p>Coming soon...</p>
                      </>
                    ) : (
                      <>
                      </>
                    )}
                  </div>
                </section>
              </Element>
              <Element name="works">
                <section>
                  <How language={language} />
                </section>
              </Element>
              <Element name="results">
                <section>
                  <Results_carrousel data={data_results} language={language} />
                </section>
              </Element>
              <Element name="pricing">
                <section>
                  <PricingBox logged={isLoggedIn} user_data={user_data} language={language} place='home' />
                </section>
              </Element>
              <section>
                <Footer language={language} />
              </section>
            </>
          )}
        </main>
      </div>
  );
}

export default App;
