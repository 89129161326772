// useFakeData.js
import { useQuery } from 'react-query';
import ApiService from './api_service';

const fetchFakeData = async ({ queryKey }) => {
  const [_, { token, isLoggedIn }] = queryKey;
  const apiService = new ApiService(token);
  let data;
  if (isLoggedIn) {
    data = await apiService.fetchFreeFake();
  } else {
    data = await apiService.fetchFreeFakeNoAuth();
  }
  return data;
};

const useFakeData = (token, isLoggedIn) => {
  return useQuery(['fakeData', { token, isLoggedIn }], fetchFakeData, {
    enabled: token !== undefined || isLoggedIn !== undefined // Only fetch data if token is present
  });
};

export default useFakeData;
