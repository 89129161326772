import React, { useState } from 'react';
import { Link, NavLink} from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Select from 'react-select';
import './Header_2.css';
import Profile_menu from './Profile_menu';

const Header_2 = (user_data) => {
  const [cookies, setCookie, removeCookie] = useCookies(['betaitoken', 'languageai']);
  const [isNavVisible, setIsNavVisible] = useState(window.innerWidth > 768);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  

  
  // check is there is a cookies.languageai, if not, set try to get language preference from browser
  if (!cookies.languageai) {
    const userLang = navigator.language || navigator.userLanguage;
    const maxAgeInSeconds = 604800; // 7 days in seconds
  
    // Check if the user's language is 'es' or 'en'; otherwise, set it to English ('en')
    const preferredLang = userLang === 'es' || userLang === 'en' ? userLang : 'en';
  
    setCookie('languageai', preferredLang, { path: '/', maxAge: maxAgeInSeconds });
  };
 

  const toggleNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageChange = (selectedOption) => {
    if (selectedOption) {
      setCookie('languageai', selectedOption.value, { path: '/', maxAge: 604800 });
    }
  };

  const languageOptions = [
    { value: 'en', label: '🇺🇸EN' },
    { value: 'es', label: '🇲🇽ES' },
  ];

  const getTranslation = (text) => {
    const translations = {
      en: {
        news: 'News',
       
      },
      es: {
        news: 'Noticias',
      
      },
    };
    const language = cookies.languageai || 'en';
    return translations[language][text];



    // post user prefered language to the server

  };

  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" className="logo-container">
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" />
        </Link>
        <nav className={"navigation"} >
    
         <NavLink to="/" activeClassName="active-link" >Picks</NavLink>
         <NavLink to="/pricing" activeClassName="active-link">Packs</NavLink>
         <NavLink to="/news" activeClassName="active-link">{getTranslation('news')}</NavLink>
          <div className="profile-dropdown">
            <Profile_menu user_data={user_data}/>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header_2;
