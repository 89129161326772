import React from "react";
import Box from "./Box";
import './Pricing.css';
import { useNavigate } from 'react-router-dom';

const translations = {
    en: {
      title: 'Pricing',
      featureBox1: [
        "✔️ Some free picks",
        "✔️ Phone notifications",
        "✔️ Help center access",

      ],
        featureBox2: [
            "✔️ 1 Full month of our best picks",
            "✔️ At least 28 picks",
            "✔️ Phone notifications",
            "✔️ Notification control",
            "✔️ Help center access",
        ],
        featureBox3: [
            "✔️ 1 Full year of our best picks",
            "✔️ At least 360 picks",
            "✔️ Phone notifications",
            "✔️ Notification control",
            "✔️ Help center access",
            "✔️ VIP Telegram chat channel access",
        ],
        start: 'Get started',

    },
    es: {
      title: 'Precios',

        featureBox1: [
           "✔️ Algunos picks gratis",
            "✔️ Notificaciones al teléfono",
            "✔️ Acceso al centro de ayuda"
        ],
        featureBox2: [
            "✔️ 1 mes completo de nuestros mejores picks",
            "✔️ Al menos 28 picks por mes",
            "✔️ Notificaciones al teléfono",
            "✔️ Control de notificaciones",
            "✔️ Acceso al centro de ayuda"
        ],
        featureBox3: [
            "✔️ 1 año completo de nuestros mejores picks",
            "✔️ Al menos 360 picks por año",
            "✔️ Notificaciones al teléfono",
            "✔️ Control de notificaciones",
            "✔️ Acceso al centro de ayuda",
            "✔️ Acceso al canal VIP de Telegram",
        ],
        start: 'Comienza',
    
    },
  };



  const PricingBox = ({ logged, user_data, language, place }) => {
    const navigate = useNavigate();
    const { title, featureBox1, featureBox2, featureBox3, start } = translations[language] || translations.en;
  
    const getBackgroundStyle = () => {
      if (place === "home") {
        return {
          background: "linear-gradient(to right, #233951, #000000)"
        };
      } else if (place === "log") {
        return {
          background: "linear-gradient(to right, #233951, #000000)"
        };
      }
    };
    console.log("User data:", user_data);
    const onButtonClick = (type) => () =>  {
      if (logged === true && user_data && user_data.user.paypal_plan_name) {
        switch(type) {
          case 'free':
            if (user_data.user.paypal_plan_name === 'free') {
              // Do something specific for the free plan
              console.log("User selected Free plan and you are free:");
              break;
            }
            else {
              console.log("User selected Free plan and you are: ", user_data.user.paypal_plan_name);
            break;
            }
          case 'pro':
            // Do something specific for the pro plan
            console.log("User selected Pro plan:", user_data.user.paypal_plan_name);
            break;
          case 'ultimate':
            // Do something specific for the ultimate plan
            console.log("User selected Ultimate plan:", user_data.user.paypal_plan_name);
            break;
          default:
            break;
        }
      } else {
        // Navigate to /login when not logged in
        navigate('/login');
      }
    };
  
    return (
      <section className="pricing" style={getBackgroundStyle()}>
        <h1 className="pricing_title">{title}</h1>
        <div className="container_pricing">
          <div className="row">
            <div className="col-md-4">
              <Box
                price="0"
                title="Free"
                btnClass="btn-primary"
                btnTitle={start}
                feature={featureBox1}
                headerColor="#F0F0F0"
                onButtonClick={onButtonClick('free')}
              />
            </div>
            <div className="col-md-4">
              <Box
                feature={featureBox2}
                price="29"
                title="Pro"
                btnClass="btn-primary"
                btnTitle={start}
                headerColor="#D0E7D2"
                onButtonClick={onButtonClick('pro')}
              />
            </div>
            <div className="col-md-4">
              <Box
                feature={featureBox3}
                price="99"
                title="Ultimate"
                btnClass="btn-primary"
                btnTitle={start}
                headerColor="#79AC78"
                onButtonClick={onButtonClick('ultimate')}
              />
            </div>
          </div>
        </div>
      </section>
    );
  };
  
  export default PricingBox;