// useUserData.js
import { useQuery } from 'react-query';
import ApiService from './api_service';

const fetchUserData = async ({ queryKey }) => {
  const [_, token] = queryKey;
  const apiService = new ApiService(token);
  const data = await apiService.fetchUserData();
  return data;
};

const useUserData = (token, isLoggedIn) => {
  return useQuery(['userData', token], fetchUserData, {
    enabled: !!token && isLoggedIn, // Only fetch data if token is present and user is logged in
  });
};

export default useUserData;