import './Count.css';
import React from 'react';

const translations = {
  en: {
    activeBets: 'Active bets:',
  },
  es: {
    activeBets: 'Apuestas activas:',
  },
};

const CountBox = ({ language, bet_count }) => {
  const { activeBets } = translations[language] || translations.en;

  if (bet_count && bet_count.count) {
    return (
      <div className="user-profile-container3">
        <div className="active-bets-message">
        <p>{activeBets} <span className="count-number">{bet_count.count}</span></p>
        </div>
      </div>
    );
  }
  return null;
};

export default CountBox;
